import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { ddmmyy, dateToTime } from "../utils/DateUtils";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";
import { CustomStyles } from "../component/CustomStyle";
import AuthContext from "../store/AuthContext";
import CachedIcon from "@mui/icons-material/Cached";
import StatusDisplay from "../StatusDisplay";
import { currencySetter } from "../utils/Currencyutil";
import ExcelUploadModal from "../modals/ExcelUploadModal";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import moment from "moment";
import CommonStatus from "../component/CommonStatus";
import { capitalize1 } from "../utils/TextUtil";
import { get } from "../network/ApiController";
import FilterCard from "../modals/FilterCard";

let refresh;
let handleCloseModal;
const AccountLedger = () => {
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState("");
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const [request, setRequest] = useState();
  const [sumData, setSumData] = useState(false);
  const [showOldTransaction, setShowOldTransaction] = useState(false);
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [isShowFilterCard, setIsShowFilterCard] = useState(false);
  const columns = [
    {
      name: "Date/Time",
      selector: (row) => (
        <div className="mb-1" style={{ textAlign: "left" }}>
          {ddmmyy(row.created_at)} {dateToTime(row.created_at)}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Particular",
      selector: (row) => (
        <Tooltip title={row?.operator}>
          <div style={{ textAlign: "left" }}>{capitalize1(row?.operator)}</div>
        </Tooltip>
      ),
      width: "185px",
      wrap: true,
    },

    {
      name: "Amount txn",
      selector: (row) => (
        <Tooltip title={row?.amount}>
          <div style={{ textAlign: "left" }}>
            {parseFloat(row?.amount).toFixed(2)}
          </div>
        </Tooltip>
      ),
    },

    {
      name: "Debit",
      selector: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "500",
            }}
          >
            {row.txn_type === "DR" && (
              <div style={{ color: "red", textAlign: "left" }}>
                -{currencySetter(row.net_amount)}
              </div>
            )}
          </Box>
        );
      },

      wrap: true,
      center: false,
    },
    {
      name: "Credit",
      selector: (row) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              textAlign: "justify",
              fontWeight: "500",
            }}
          >
            {row.txn_type === "CR" && (
              <div style={{ color: "green", textAlign: "left" }}>
                +{currencySetter(row.net_amount)}
              </div>
            )}
          </Box>
        );
      },

      wrap: true,
      center: false,
    },
    {
      name: "Balance",
      selector: (row) => {
        return (
          <Tooltip title={row.ip}>
            {(user.role === "Ad" || user.role == "Md") && (
              <Typography>{row.w1}</Typography>
            )}

            {(user.role == "Dd" || user.role == "Ret") && (
              <>
                <Typography align="left">{currencySetter(row.w1)}</Typography>
                <Typography align="left">{currencySetter(row.w2)}</Typography>
              </>
            )}
          </Tooltip>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <CommonStatus
            status={row?.status}
            approvedStatusText="Success"
            pendingStatusText="Pending"
            rejectedStatusText="Failed"
            refundStatusText="Refund"
            fontSize="13px"
            minWidth="90px"
            maxWidth="100px"
          />
        </>
      ),
      center: true,
    },
  ];

  const getExcel = () => {
    get(
      ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      showOldTransaction && showOldTransaction
        ? ApiEndpoints.OLD_TRANSACTIONS
        : ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  function refreshFunc(setQuery) {
    setQuery("");
    if (refresh) refresh();
  }

  return (
    <Box>
       {user?.aggreement === 1 ? (
      <Grid container>
        <ApiPaginateSearch
        
          totalCard={
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 0.5 }}
              >
                <Grid item lg={12} sx={12} md={5}>
                  <FilterCard
                    ifdateFilter
                    setQuery={setQuery}
                    query={query}
                    refresh={refresh}
                    isShowFilterCard={isShowFilterCard}
                    setIsShowFilterCard={setIsShowFilterCard}
                    actionButtons={
                      <>
                        <ExcelUploadModal
                          btn
                          request={request}
                          getExcel={getExcel}
                          noOfResponses={noOfResponses}
                          setQuery={setQuery}
                          handleCloseCB={(closeModal) => {
                            handleCloseModal = closeModal;
                          }}
                        />
                        <Tooltip title="refresh">
                          <IconButton
                            aria-label="refresh"
                            sx={{
                              color: "#0F52BA",
                            }}
                            onClick={() => {
                              refreshFunc(setQuery);
                            }}
                          >
                            <CachedIcon className="refresh-purple " />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                </Grid>

                {/* <Grid item lg={7} sx={12} md={7}>
                  <StatusDisplay sumData={sumData} setSumData={setSumData} />
                </Grid> */}
              </Grid>
            </>
          }
          setSumData={setSumData}
          // actionButtons={

          // }
          showSearch={false}
          isFilterAllowed
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          columns={columns}
          apiData={apiData}
          setApiData={setApiData}
          tableStyle={CustomStyles}
          queryParam={query ? `${query}&type_txn=LEDGER` : `type_txn=LEDGER`}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
          responses={(val) => {
            setNoOfResponses(val);
          }}
        />
      </Grid>
       ):(

 <Box>
         <Grid
                    item
                    className="card-css"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      p: 4,
        
                      position: "absolute",
                      top: "50%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
        
                      backgroundColor: " rgb(73, 132, 200) ",
                      borderRadius: "10px",
                      boxShadow: 3,
                      maxWidth: "600px",
        
                      height: "300px",
                    }}
                  >
                    <Box sx={{ textAlign: "center", color: "#fff", width: "100%" }}>
                      <h5
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginBottom: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        To use our services, please sign the agreement
                      </h5>
        
                      <br />
                      {user.role === "Ret" || user.role === "Dd" && (
                        <a
                          href="/service"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#fff", // White color for the link
                            fontSize: "18px", // Slightly larger font for the link
                            fontWeight: "500", // Medium weight for the link
                            textDecoration: "none", // No underline by default
                            backgroundColor: "rgb(220, 42, 113)", // Dark blue background for a button effect
                            padding: "8px 20px", // Padding inside the button
                            borderRadius: "30px", // Rounded button shape
                            display: "inline-block",
                            transition: "background-color 0.3s",
                          }}
                          onMouseOver={(e) => {
                            e.target.style.backgroundColor = "rgb(26, 32, 38)"; // Darken blue on hover
                          }}
                          onMouseOut={(e) => {
                            e.target.style.backgroundColor = "rgb(38, 50, 56)"; // Revert to original color
                          }}
                        >
                          Click here
                        </a>
                      ) 
                    }
                    </Box>
                  </Grid>
        </Box>
       )}
    </Box>
  );
};

export default AccountLedger;
