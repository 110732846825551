import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export const UtilityReciept = () => {
  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="body2">
            <strong>ImpsGuru</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
