import * as React from "react";
import Box from "@mui/material/Box";
import {
  Modal,
  Typography,
  Grid,
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import ModalHeader from "./ModalHeader";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import { useState } from "react";
import Loader from "../component/loading-screen/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { CircularProgress } from "@mui/material";
import { Loading } from "@nextui-org/react";

const AdjustEntriesModal = ({
  isModalOpen,
  handleModalClose,
  selectedRows,
  defaultTxn,
  setDefaultTxn,
  onDefaultTxnChange,
  accounts,
  rowStatus,
  setRowStatus,
}) => {
  const [request, setRequest] = useState(false);
  const [remainingCount, setRemainingCount] = useState(selectedRows.length);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedRows || selectedRows.length === 0) {
      apiErrorToast("No rows selected to adjust.");
      return;
    }

    setRequest(true);

    try {
      const initialStatus = selectedRows.reduce((acc, row) => {
        acc[row.id] = "Processing...";
        return acc;
      }, {});
      setRowStatus(initialStatus);

      for (const row of selectedRows) {
        const payload = {
          bank_name: row?.bank,
          bank_txn_id: row?.id,
          given: row?.debit,
          taken: row?.credit,
          particulars: row?.description,
          remark: row?.description,
          user_id: defaultTxn?.mobile,
        };

        await postJsonData(
          ApiEndpoints.ADD_ACCOUNT_TXN,
          payload,
          setRequest,
          (res) => {
            setRowStatus((prevStatus) => ({
              ...prevStatus,
              [row.id]: "Success",
            }));
            setRemainingCount((prevCount) => prevCount - 1);
            console.log(`API Response for row: ${row.name}`, res);
          },
          (error) => {
            setRowStatus((prevStatus) => ({
              ...prevStatus,
              [row.id]: `Error: ${error.message}`,
            }));
            console.error(`Error processing row ${row.name}: ${error.message}`);
          }
        );
      }
             okSuccessToast("ENTRIES ADDES SUCCESSFULLY");
             handleModalClose()
            
    } catch (error) {
      apiErrorToast(error.message || "An error occurred during adjustment.");
      console.error("Error details:", error);
    } finally {
      setRequest(false);
    }
  };

  const remainingRowsCount = selectedRows.filter((row) => {
    return (
      rowStatus[row.id] === "Processing..." ||
      !rowStatus[row.id] ||
      rowStatus[row.id]?.includes("Error")
    );
  }).length;

  const allRowsSuccess = selectedRows.every(
    (row) => rowStatus[row.id] === "Success"
  );
  return (
    <Box>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", sm: "80%", md: "60%", lg: "50%" },
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <ModalHeader
            title="Adjust Entry"
            handleClose={handleModalClose}
            disableCloseButton={request}
          />


          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // This pushes content to the edges
              flexDirection: { xs: "column", sm: "column", md: "row" }, // Column on small screens, row on larger screens
              alignItems: { xs: "flex-start", sm: "flex-start", md: "center" }, // Align text and autocomplete properly
              mb: 3,
              borderBottom: "1px solid #ddd",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{
                  mb: { xs: 2, sm: 0 },
                }}
              >
                Total Selected Rows: {selectedRows?.length || 0}
              </Typography>

              <Typography
                variant="body1"
                component="h2"
                sx={{ mb: { xs: 2, sm: 0 } }}
              >
                Remaining Entries: {remainingRowsCount}
              </Typography>
            </Box>

            <Autocomplete
              options={accounts || []}
              getOptionLabel={(option) =>
                option?.name && option?.mobile
                  ? `${option.name} / ${option.mobile}`
                  : ""
              }
              value={defaultTxn || null}
              onChange={(event, newValue) => setDefaultTxn(newValue)}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Account"
                  size="small"
                  variant="outlined"
                  required
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name && option.mobile === value?.mobile
              }
              filterOptions={(options, state) =>
                options.filter((option) =>
                  `${option.name} ${option.mobile}`
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                )
              }
              sx={{
                minWidth: { xs: "100%", sm: 400, md: 500 },
                color: "#000",
              }}
            />
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "scroll",
              height: "40vh",
            }}
          >
            {selectedRows.length > 0 &&
              selectedRows.map((row) => (
                <Grid item xs={12} key={row.id}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid #ddd",
                      padding: "8px 0",
                    }}
                  >
                    <Typography variant="body2">
                      {row?.id} \\ {row?.bank} \\ {row?.description}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {rowStatus[row.id] === "Processing..." && (
                        <CircularProgress size={22} color="inherit" />
                      )}
                      {rowStatus[row.id] === "Success" && (
                        <CheckCircleIcon sx={{ color: "green" }} />
                      )}
                      {rowStatus[row.id]?.includes("Error") && (
                        <ErrorIcon sx={{ color: "red" }} />
                      )}
                      {!rowStatus[row.id] || rowStatus[row.id] === "Pending" ? (
                        <HourglassEmptyIcon sx={{ color: "orange" }} />
                      ) : null}
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          color:
                            rowStatus[row.id] === "Success"
                              ? "green"
                              : rowStatus[row.id]?.includes("Error")
                              ? "red"
                              : "orange",
                        }}
                      >
                        {rowStatus[row.id] || "Pending"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              mt: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6} sm="auto">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleModalClose}
                disabled={request}
              >
                Close
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={
                  request ||
                  !selectedRows?.length ||
                  !defaultTxn ||
                  allRowsSuccess
                }
                startIcon={
                  request && <CircularProgress size={16} color="inherit" />
                }
              >
                {request ? "Processing..." : "Adjust Entry"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdjustEntriesModal;
