import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomTabs from "../component/CustomTabs";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"; // New icon for "Account Limit"
import LockIcon from "@mui/icons-material/Lock"; // New icon for "Blocked Account"
import AdminAccountLimit from "./AdminAccountLimit";
import { useTheme } from "@mui/material/styles";
import AdminBlockedAc from "./AdminBlockedAc";
import { mt_tab_value } from "../utils/constants";

let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}

let refreshFilter;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminRiskView = () => {
  const theme = useTheme();
  const [query, setQuery] = useState();
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState();
  console.log("set value is hear", value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentType(mt_tab_value[newValue]);
  };

  const tabs = [
    { label: "Account Limit", icon: <AccountBalanceWalletIcon />, content: <AdminAccountLimit /> },
    { label: "Blocked Account", icon: <LockIcon />, content: <AdminBlockedAc /> },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabs
        tabs={tabs}
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default AdminRiskView;
