import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast } from "../utils/ToastUtil";
// import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import DmrNumberListModal from "../modals/DmrNumberListModal";
import DmrAddBeneficiaryModal from "../modals/DmrAddBeneficiaryModal";
import DmrAddRemitterModal from "../modals/DmrAddRemitterModal";
import DmrVrifyNewUser from "../modals/DmrVrifyNewUser";
import Loader from "../component/loading-screen/Loader";
import BeneSearchBar from "../component/BeneSearchBar";
import { PATTERNS } from "../utils/ValidationUtil";
import { capitalize1 } from "../utils/TextUtil";
import {
  getEnv,
  getTableHeadRowColor,
  randomColors,
} from "../theme/setThemeColor";
import AuthContext from "../store/AuthContext";
import OutletRegistration from "../component/OutletRegistration";
import { mt_tab_value, PROJECTS, vendor_tab_value } from "../utils/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { banking } from "../_nav";
import HNavButton from "../component/HNavButton";
import { useNavigate } from "react-router-dom";
import { currencySetter } from "../utils/Currencyutil";
import BeneCardComponent from "../component/BeneCardComponent";
import BeneCardVender from "../component/BeneCardVender";
import {
  back,
  Call1,
  LimitAcc,
  LimitTran,
  Name,
  recent,
} from "../iconsImports";
import NoDataView from "../component/NoDataView";
import CustomTabs from "../component/CustomTabs";
import BbpsCardComponent from "../component/BbpsCardComponent";
import CommonCardServices from "./CommonCardServices";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const VendorPayments = ({
  resetView,
  id,
  type="express",
  dataCategories=false,
  handleCardClick,
}) => {
  const [infoFetchedMob, setInfoFetchedMob] = useState(false);
  const [request, setRequest] = useState(false);
  const [remitterStatus, setRemitterStatus] = useState();
  const [mobile, setMobile] = useState("");
  const [bene, setBene] = useState([]);
  const [verifyotp, setVerifyotp] = useState(false);
  const [addNewRem, setAddNewRem] = useState(false);
  const [otpRefId, setOtpRefId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredBenelist, setFilteredBenelist] = useState([]);
  const [isMobv, setIsMobv] = useState(true);
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const envName = getEnv();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState(0);
  const scrollContainerRef = useRef(null);

  const handleBack = () => {
    console.log("This is yoyr resetView", resetView);
    resetView(false);
  };
  //

  console.log("type is ", type);

  // useEffect(()=>{
  //   settype(type)
  // })

  useEffect(() => {
    if (user) {
      // console.log("here in if", user);
      // if (!infoFetchedMob) {
      //   settype(
      //     user?.dmt4 === 1 && user?.st === 1
      //       ? "express"
      //       : user?.dmt4 === 1
      //       ? "express"
      //       : user?.st === 1
      //       ? "super"
      //       : "express"
      //   );
      // }
    }
  }, [user]);

  const getRemitterStatus = (number) => {
    postJsonData(
      type === "express"
        ? ApiEndpoints.NEW_GET_REMMITTER_STATUS
        : ApiEndpoints.GET_REMMITTER_STATUS_SUPER,
      {
        number: number,
      },
      setRequest,
      (res) => {
        if (res && res?.status === 200 && res?.data?.message === "OTP Sent") {
          setOtpRefId(res?.data?.otpReference);
          setVerifyotp(true);
        } else if (res && res?.data && res?.data) {
          const data = res?.data;
          if (res?.data?.remitter && res?.data?.message === "Verify Remitter") {
            setVerifyotp(true);

            // setOtpRefId(res.data.otpReference);
          }
          setMobile(number);
          setRemitterStatus(data?.remitter);
          setBene(data?.data);
          setInfoFetchedMob(true);
          setNumberList("");
        } else {
          setRemitterStatus();
        }
      },
      (error) => {
        if (error && error) {
          if (
            error.response.status === 404 &&
            error.response.data.message === "Remitter Not Found"
          ) {
            setAddNewRem(true);
          } else {
            apiErrorToast(error);
          }
        }
      }
    );
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const refreshRemitterStatus = (number) => {
    postJsonData(
      ApiEndpoints.REF_REMMITTER_STATUS,
      {
        number: number,
      },
      setRequest,
      (res) => {
        if (res && res.status === 200 && res.data.message === "OTP Sent") {
          setOtpRefId(res.data.otpReference);
          setVerifyotp(true);
        } else if (res && res.data && res.data) {
          const data = res.data;
          setMobile(number);
          setRemitterStatus(data.remitter);
          setBene(data.data);
          setInfoFetchedMob(true);
          setNumberList("");
        } else {
          setRemitterStatus();
        }
      },
      (error) => {
        if (error && error) {
          if (
            error.response.status === 404 &&
            error.response.data.message === "Remitter Not Found"
          ) {
            setAddNewRem(true);
          } else {
            // apiErrorToast(error);
          }
        }
      }
    );
  };
  useEffect(() => {
    if (search) {
      const myList = bene.filter((item) => {
        return item.bene_name.toUpperCase().includes(search.toUpperCase());
      });
      setFilteredBenelist(myList);
    } else {
      setFilteredBenelist(bene);
    }

    return () => {};
  }, [search, bene]);
  const returnMoneyNew = () => {
    setInfoFetchedMob(false);
  };
  const [numberList, setNumberList] = useState([]);

  // const getRemitterStatusByAcc = (event) => {
  //   event.preventDefault();
  //   const number = document.getElementById("acc")?.value;
  //   postJsonData(
  //     ApiEndpoints.GET_REMMITTER_STATUS_ACC,
  //     {
  //       // number: number,
  //       number: mobile,
  //     },
  //     setRequest,
  //     (res) => {
  //       if (res && res.data && res.data) {
  //         const data = res.data.data;
  //         if (data?.length > 0) {
  //           setNumberList(data);
  //           document.getElementById("acc").value = "";
  //           document.getElementById("acc").focus = "off";
  //         } else {
  //           // apiErrorToast("No Vendor Found! Kindly Change Account Number");
  //         }
  //       } else {
  //         setRemitterStatus();
  //       }
  //     },
  //     (error) => {
  //       apiErrorToast(error);
  //     }
  //   );
  // };
  const tabs = [{ label: "Vendor Payment" }, { label: "Super" }];
  const handleChange = (event, newValue) => {
    console.log("newval", newValue);
    setValue(newValue);
    // settype(vendor_tab_value[newValue]);
    setCurrentType(newValue);

    console.log("vendor type", type);
  };

  return (
    <>
      {envName === PROJECTS.moneyoddr && infoFetchedMob && (
        <div style={{ textAlign: "left", marginBottom: "10px" }}>
          <Button
            className="button-red"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            sx={{ py: 0.2, px: 2 }}
            onClick={() => {
              setInfoFetchedMob(false);
              setRemitterStatus();
              setMobile("");
            }}
          >
            back
          </Button>
        </div>
      )}
      {/* {user && !user.instId && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <OutletRegistration autoOpen />
        </Box>
      )} */}
      {user && (
        <>
          {/* {user?.layout  && (
            <Box
              className="card-css"
              sx={{
                width: "100%",
                my: 2,
                p: 2,
                py: 1,
              }}
            >
              <Typography className="services-heading">
                Banking Services
              </Typography>
              <Grid container>
                {user?.st === 0 ||
                user.dmt4 === 0 ||
                user?.aeps === 0 ||
                user?.nepal_transfer === 0 ||
                user?.upi_transfer === 0
                  ? banking
                      .filter((item) => {
                        if (user?.st === 0 && item.title === "Super Transfer") {
                          return undefined;
                        }
                        if (
                          user?.dmt4 === 0 &&
                          item.title === "Express Transfer"
                        ) {
                          return undefined;
                        }
                        if (user?.aeps === 0 && item.title === "AEPS") {
                          return undefined;
                        }
                        if (
                          user?.nepal_transfer === 0 &&
                          item.title === "Nepal Transfer"
                        ) {
                          return undefined;
                        }
                        if (
                          user?.upi_transfer === 0 &&
                          item.title === "UPI Transfer"
                        ) {
                          return undefined;
                        } else {
                          return item;
                        }
                      })
                      .map((mitem, index) => {
                        return (
                          <Grid
                            item
                            md={2}
                            index={index}
                            onClick={() => navigate(mitem.to)}
                            className="horizontal-sidenav"
                          >
                            <HNavButton item={mitem} />
                          </Grid>
                        );
                      })
                  : banking?.map((item, index) => {
                      return (
                        <Grid
                          item
                          md={2}
                          index={index}
                          onClick={() => navigate(item.to)}
                          className="horizontal-sidenav"
                        >
                          <HNavButton item={item} />
                        </Grid>
                      );
                    })}
              </Grid>
            </Box>
          )} */}

          <div
            // style={{
            //   display: "flex",
            //   justifyContent: "center",
            //   height: "90vh",
            //   alignItems: infoFetchedMob
            //     ? "flex-start"
            //     : user?.layout && user?.layout === 2
            //     ? "start"
            //     : "center",
            // }}
            className="position-relative"
          >
            <Loader circleBlue loading={request} />
            {/* initial form */}
            {!addNewRem && (
              <Grid container sx={{ display: "flex" }}>
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                  md={12}
                  sx={{
                    mb: { md: 2, sm: 6, xs: 4 },
                    // mr: { md: 0, sm: 1.3, xs: 1.3 },
                    ml:1.4
                  }}
                >
                  {dataCategories?(dataCategories
                    .filter((category) => category.title === "Banking")
                    .map((category, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          margin: "0 auto",
                          marginBottom: 1,
                          border: "1px solid lightgray",
                          padding: { lg: 1.1, md: 1.2, xs: 2, sm: 3 },
                          borderRadius: 3,

                          paddingRight: 1,
                          flexWrap: "wrap",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        <Typography
                          variant="h6"
                          align="left"
                          sx={{
                            ml: 0.5,
                            mt: "-8px",
                            fontSize: { xs: "1rem", sm: "1.25rem" },
                          }}
                        >
                          {category.title}
                        </Typography>

                        <Box
                          sx={{
                            // overflowX: "scroll",
                            height: "100px",
                            display: "flex",
                            mt: -2,
                            flexDirection: "row",
                            width: {
                              lg: "67vw",
                              md: "67vw",
                              sm: "82vw",
                              xs: "82vw",
                            },
                          }}
                        >
                          <IconButton
                            onClick={() => handleScroll("left")}
                            sx={{
                              position: "absolute",
                              left: 11,
                              mt: 4,
                              zIndex: 1,
                              color: "#000",
                            }}
                          >
                            <ArrowBackIosIcon />
                          </IconButton>
                          <Box
                            ref={scrollContainerRef}
                            sx={{
                              display: "flex",
                              width: "100%",
                              overflowX: "auto",
                              scrollBehavior: "smooth",
                              padding: 1,
                              "&::-webkit-scrollbar": {
                                display: "none", // Hide the scrollbar
                              },
                            }}
                          >
                            {category.data.map((item) => (
                              <Box
                                sx={{
                                  px: 1,
                                }}
                              >
                                <CommonCardServices
                                  title={item.name}
                                  img={item.img}
                                  isSelected={id === item.id}
                                  onClick={() => handleCardClick(item)}
                                  width="200px"
                                />
                              </Box>
                            ))}
                          </Box>
                          <IconButton
                            onClick={() => handleScroll("right")}
                            sx={{
                              position: "absolute",
                              right: 0,
                              mt: 4,
                              zIndex: 1,
                              color: "#000",
                            }}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))):[]}
                  {/* {!infoFetchedMob &&
                    !infoFetchedMob &&
                    !addNewRem &&
                    !addNewRem &&
                    !verifyotp &&
                    !verifyotp && (
                      <CustomTabs
                        tabs={tabs}
                        value={value}
                        onChange={handleChange}
                      />
                    )} */}
                  <Card
                    className="card-css"
                    sx={{
                      width: "100%",
                      px: 1.3,
                      pb: 1.3,
                    }}
                  >
                    {!infoFetchedMob &&
                      !infoFetchedMob &&
                      !addNewRem &&
                      !addNewRem &&
                      !verifyotp &&
                      !verifyotp && (
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            mr: 2,
                            mt: 1,
                          }}
                        >
                          {/* Back Arrow */}
                          {dataCategories&&
                          <Grid
                            item
                            sx={{
                              position: "absolute",
                              left: 0,
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={handleBack}
                          >
                            <ArrowBackIcon />
                          </Grid>
}
                          {/* Typography */}
                          <Grid item>
                            <Typography
                              sx={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                letterSpacing: "0.05rem",
                                textAlign: "center",
                              }}
                            >
                              {type === "express"
                                ? "Wallet Transfer"
                                : "Payments"}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    {/* //   <Grid
                      //     item
                      //     xs={12}
                      //     sm="auto"
                      //     sx={{
                      //       display: "flex",
                      //       alignItems: "center",
                      //       justifyContent: "space-between",
                      //       mr: 2,
                      //       mt: 2,
                      //     }}
                      //   >
                      //     <Button
                      //       size="small"
                      //       id="verify-btn"
                      //       className="button-props"
                      //       onClick={handleBack}
                      //     >
                      //       <span style={{ marginRight: "5px" }}>Home</span>
                      //       <img
                      //         src={back}
                      //         alt="back"
                      //         style={{ width: "18px", height: "20px" }}
                      //       />
                      //     </Button>
                      //   </Grid>
                      // )}
                    /* {!mobile ? (
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          letterSpacing: "0.05rem",
                          textAlign: "left",
                          mt: 1,
                        }}
                      >
                        {type == "express"
                          ? "Vendor Payment"
                          : "Super Money Transfer"}
                      </Typography>
                    // ) : null} */}
                    <Box
                      component="form"
                      id="seachRemByAcc"
                      sx={{
                        "& .MuiTextField-root": { mt: 2 },
                        objectFit: "contain",
                        overflowY: "scroll",
                      }}
                      // onSubmit={getRemitterStatusByAcc}
                    >
                      <Grid
                        container
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          mb: { md: 1, sm: 4, xs: 4 },
                          mr: { md: 0, sm: 1.3, xs: 1.3 },
                        }}
                      >
                        <Grid item md={12} xs={12}>
                          {!infoFetchedMob &&
                            !infoFetchedMob &&
                            !addNewRem &&
                            !addNewRem &&
                            !verifyotp &&
                            !verifyotp && (
                              <FormControl sx={{ width: "100%" }}>
                                <TextField
                                  autoComplete="off"
                                  label="Mobile Number"
                                  id="mobile"
                                  name="mobile"
                                  type="number"
                                  value={mobile}
                                  size="small"
                                  required
                                  onChange={(e) => {
                                    setIsMobv(
                                      PATTERNS.MOBILE.test(e.target.value)
                                    );
                                    if (e.target.value === "") {
                                      setRemitterStatus("");
                                      setInfoFetchedMob(false);
                                      bene && setBene([]);

                                      setIsMobv(true);
                                    }

                                    setMobile(e.target.value);
                                    if (e.target.value.length === 9) {
                                      setRemitterStatus("");
                                      setInfoFetchedMob(false);
                                      bene && setBene([]);
                                    }
                                    if (PATTERNS.MOBILE.test(e.target.value)) {
                                      getRemitterStatus(e.target.value);
                                    }
                                  }}
                                  error={!isMobv}
                                  helperText={
                                    !isMobv ? "Enter valid Mobile Number" : ""
                                  }
                                  inputProps={{
                                    form: {
                                      autocomplete: "off",
                                    },
                                    maxLength: "10",
                                    
                                    inputMode: "numeric", 
                                  }}
                                  disabled={request && request && true}
                                />
                              </FormControl>
                            )}
                        </Grid>

                        {infoFetchedMob && infoFetchedMob && (
                          <Grid width="100%">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "-12px",
                              }}
                            >
                              <Button
                                sx={{
                                  display: "flex",
                                  alignItems: "left",
                                }}
                                onClick={() => {
                                  setInfoFetchedMob(false);
                                  setRemitterStatus();
                                  setMobile("");
                                }}
                              >
                                <ArrowBackIcon
                                  sx={{ color: "#000", fontSize: "24px" }}
                                />
                              </Button>

                              <h1
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "600",
                                  color: "#1877f2",
                                  textAlign: "center",
                                  margin: "0 auto",
                                }}
                              >
                                {type == "express"
                                  ? "Wallet Transfer"
                                  : "Payments"}
                              </h1>
                            </div>
                            <Grid
                              className="remitter-card"
                              container
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mt: -0.5,
                                ml:2.5,
                                width:"95.5%",
                              }}
                            >
                              {/* Name and Number Section */}
                              <Grid
                                item
                                lg={4.5}
                                md={4.5}
                                sm={4.5}
                                xs={4.5}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2, // Adds spacing between items in this grid
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={Name}
                                    alt="Name"
                                    sx={{
                                      maxWidth: "25px",
                                      maxHeight: "50px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {remitterStatus?.name}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={Call1}
                                    alt="Call"
                                    sx={{
                                      maxWidth: "25px",
                                      maxHeight: "50px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {remitterStatus?.number}
                                  </Typography>
                                  <BorderColorIcon
                                    sx={{
                                      color: "black",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                    }}
                                    onClick={returnMoneyNew}
                                  />
                                </Box>
                              </Grid>

                              {/* Limit Section */}

                              {/* Add Beneficiary Section */}
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={3}
                                xs={3}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center", // Centers content horizontally
                                  alignItems: "center", // Centers content vertically
                                }}
                              >
                                <DmrAddBeneficiaryModal
                                  type={type}
                                  rem_mobile={mobile}
                                  getRemitterStatus={
                                    type === "dmt1"
                                      ? refreshRemitterStatus
                                      : getRemitterStatus
                                  }
                                  apiEnd={
                                    type === "express"
                                      ? ApiEndpoints.ADD_BENE_EXPRESS
                                      : ApiEndpoints.ADD_BENE_SUPER
                                  }
                                  view="MT_View"
                                  sx={{ width: "auto" }}
                                />
                              </Grid>
                              <Grid
                                item
                                lg={4.5}
                                md={4.5}
                                sm={4.5}
                                xs={4.5}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2, // Adds consistent spacing between rows
                                  alignItems: "flex-end",
                                }}
                              >
                                {/* First Box */}

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: 1,
                                    mr: 4.5,
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={LimitAcc}
                                    alt="Limit"
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Limit Available:
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {type
                                      ? remitterStatus?.rem_limit
                                      : remitterStatus?.limitTotal}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={LimitTran}
                                    alt="Limit"
                                    sx={{
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Limit Per Transaction:
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    {/* {type === "dmt2" ? 5000 : remitterStatus?.limitPerTransaction} */}
                                    5000
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {infoFetchedMob && infoFetchedMob && (
                      <Grid
                        lg={12}
                        sm={12}
                        xs={12}
                        md={12}
                        sx={{ mb: { md: 2, sm: 2, xs: 2 }, mt: -1 ,mx: { md: 2, sm: 1, xs: 0,lg:2.4 },}}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                            mx: { md: 2, sm: 1, xs: 0 },
                            mr: { xs: 1.3, md: 2,md:1,lg:1 },
                            mb: 0.3
                          }}
                        >
                          <Box
                            sx={{
                              flex: 1,
                              maxWidth: {
                                lg: "100%",
                                md: "200px",
                                sm: "200px",
                                xs: "100%",
                              }, // Adjust max-width based on screen size
                            }}
                          >
                            <BeneSearchBar
                              setSearch={setSearch}
                              label="Search Wallet"
                              remMargin={true}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              mx: { md: 2, sm: 1, xs: 0 },
                              mr: { xs: 1.3, md: 2 },
                            }}
                          >
                            <Typography sx={{ fontSize: "18px", mb: 1 }}>
                              Wallet List  ({filteredBenelist.length}) 
                            </Typography>
                          </Box>
                        </Box>
                        <div
                          className="
                       enable-scroll "
                          style={{
                            overflow: "auto", // Ensure that the overflow behavior is automatic
                            height: "85vh", // Fixed height to control the scrolling area
                            paddingBottom: "8px",
                            "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for Webkit browsers
                            msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
                            scrollbarWidth: "none",
                          }}
                        >
                          {bene?.length <= 0 ? (
                            <Typography sx={{ mt: 2 }}>
                              No Beneficiary found.
                              <Typography sx={{ fontWeight: "bold" }}>
                                Enter Remitter's Mobile Number to view
                                Beneficiary List
                              </Typography>
                              <NoDataView />
                            </Typography>
                          ) : filteredBenelist?.length <= 0 ? (
                            <Typography sx={{ mt: 2 }}>
                              No Beneficiary found.
                            </Typography>
                          ) : (
                            <Grid container spacing={0.5}>
                              {filteredBenelist.map((ben, index) => {
                                return (
                                  <Grid item md={12} lg={12} sm={12} xs={12}>
                                    <BeneCardVender
                                      type={type}
                                      ben={ben}
                                      index={index}
                                      mobile={mobile}
                                      remitterStatus={remitterStatus}
                                      getRemitterStatus={getRemitterStatus}
                                      view="MT_View"
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </div>
                      </Grid>
                    )}
                  </Card>

                  {/* {numberList && numberList.length > 0 && ( */}
                  <DmrNumberListModal
                    numberList={numberList}
                    setMobile={(mob) => {
                      setMobile(mob);
                      getRemitterStatus(mob);
                    }}
                  />
                  {/* )} */}
                </Grid>
              </Grid>
            )}
            {addNewRem && addNewRem && (
              <DmrAddRemitterModal
                rem_mobile={mobile}
                dmtValue={type}
                registeredData={remitterStatus}
                getRemitterStatus={getRemitterStatus}
                // apiEnd={ApiEndpoints.ADD_REM}
                apiEnd={
                  type === "express"
                    ? ApiEndpoints.ADD_REM_EXPRESS
                    : ApiEndpoints.ADD_REM_SUPER
                }
                view="expressTransfer"
                setAddNewRem={setAddNewRem}
                setOtpRef={setOtpRefId}
              />
            )}
            {verifyotp && verifyotp && (
              <DmrVrifyNewUser
                rem_mobile={mobile}
                getRemitterStatus={getRemitterStatus}
                view="expressTransfer"
                verifyotp={verifyotp}
                dmtValue={type}
                apiEnd={ApiEndpoints.VALIDATE_SUP_OTP}
                otpRefId={otpRefId && otpRefId}
                setOtpRefId={setOtpRefId}
                setVerifyotp={setVerifyotp}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VendorPayments;
