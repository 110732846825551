import React from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import RetExpresTransferModal from "../modals/RetExpresTransferModal";
import ApiEndpoints from "../network/ApiEndPoints";
import DeleteBeneficiaryModal from "../modals/DeleteBeneficiaryModal";
import AccountVerificationModal from "../modals/AccountVerificationModal";
import { capitalize1 } from "../utils/TextUtil";
import { randomColors } from "../theme/setThemeColor";
import VerifiedIcon from "@mui/icons-material/Verified";
import PortBeneficiaries from "../modals/PortBeneficiaries";
import AccountVerificationUpi from "../modals/AccountVerificationUpi";
import RetUpiTransferModal from "../modals/RetUpiTransferModal";
import { upi2 } from "../iconsImports";
import CommonMoneyTransferModal from "../modals/CommonMoneyTransferModal";

const BeneCardUpi = ({
  ben,
  index,
  mobile,
  remitterStatus,
  getRemitterStatus,
}) => {
  return (
    <Box
      className="card1-css"
      key={index}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        px: 2,
        py: 1.3,
        ml: 6,
        mr: 6,
        mt: 1,
        position: "relative",
        gap: 2,
        borderRadius: "8px",
        boxShadow: "1px 0.5px 0.5px 1px rgb(214, 187, 251)",
        border: "1px solid #D6BBFB",
        // Minimal shadow for compact design
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Keeping the items in a row
          gap: 2, // Reduced gap between items for compact design
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%", // Ensure the Box takes full width
        }}
      >
        <Grid
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            // background: randomColors(
            //   ben && ben.name
            //     ? ben.name.charAt(0).toUpperCase()
            //     : ben.bene_name.charAt(0).toUpperCase()
            // ),
            backgroundColor: "#D0D0D0",
            borderRadius: "4px",
            height: "64px",
            width: "64px",
            position: "relative",
            p: 1,
          }}
        >
          {/* <Typography sx={{ fontSize: "40px" }}>
          {ben && ben.name
            ? ben.name.charAt(0).toUpperCase()
            : ben.bene_name.charAt(0).toUpperCase()}
        </Typography> */}
          <img
            src={upi2}
            alt="Bank Logo"
            style={{ width: "40px", height: "40px" }}
          />
        </Grid>

        <Grid
          sx={{
            width: "100%",
            ml: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <Typography sx={{ textAlign: "left", fontWeight: "600" }}>
                VPA
              </Typography>
              <Typography sx={{ textAlign: "left", fontSize: "12px" }}>
                {ben.account ? ben.account : ben.bene_acc}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              mt: { md: 2, sm: 1, xs: 1, lg: 2 },
              justifyContent: "flex-end", // Align to the right
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
              {ben.last_success_date ? (
                <>
                  <Typography sx={{ color: "#1977f2" }}>Verified</Typography>
                  <VerifiedIcon
                    sx={{ fontSize: "17px", color: "#1977f2", mr: 0.5 }}
                  />
                </>
              ) : (
                <AccountVerificationUpi rem_number={mobile} ben={ben} />
              )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: 1 }}>
              {/* <RetUpiTransferModal ben={ben} rem_number={mobile} /> */}

              <CommonMoneyTransferModal
                ben={ben}
                rem_number={mobile}
                view={"upi"}
                dmtValue={"Upi"}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Position the Delete button in the top right corner */}
        <Box sx={{ position: "absolute", top: "-10px", right: "-10px" }}>
          <DeleteBeneficiaryModal
            bene={ben}
            mob={mobile}
            getRemitterStatus={getRemitterStatus}
            apiEnd={ApiEndpoints.REMOVE_BENE_UPI}
            view="expressTransfer"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BeneCardUpi;
