import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Switch,
  TextField,
  FormControl,
  Typography,
  Button,
  Card,
} from "@mui/material";
import AuthContext from "../store/AuthContext";
import { postFormData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { recent, upiWeb } from "../iconsImports";
import QRCode from "react-qr-code";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Loader from "../component/loading-screen/Loader";
import { numberSetter } from "../utils/Currencyutil";
import WalletTransfer from "../modals/WalletTransfer";
import useCommonContext from "../store/CommonContext";
import OutletRegistration from "./OutletRegistration";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import QRWarningModal from "../modals/QRWarningModal";
import { validateApiCall } from "../utils/LastApiCallChecker";
import Mount from "./Mount";
import RefreshComponent from "./RefreshComponent";
import RecentHistory from "./right_sidenav/RecentHistory";
import BankTransfer from "./right_sidenav/BankTransfer";
import AddBalanceViaPG from "../modals/AddBalanceViaPG";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { gradientColor, randomTheme } from "../theme/setThemeColor";
import { SendMoney } from "./right_sidenav/SendMoney";
import CallMadeIcon from "@mui/icons-material/CallMade";

const RightSideCard = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const userLat = authCtx.location && authCtx.location.lat;
  const userLong = authCtx.location && authCtx.location.long;
  const theame = authCtx.theame;
  const [showQr, setShowQr] = useState(false);
  const [open, setOpen] = useState(false);
  const [showWalletTransfer, setShowWalletTransfer] = useState(false);
  const [showBankTransfer, setShowBankTransfer] = useState(false);
  const [showSendMoney, setshowSendMoney] = useState(false);
  const instId = user && user.instId;
  const [walletTransferErrMsg, setWalletTransferErrMsg] = useState("");
  const [request, setRequest] = useState(false);
  const { getRecentData, refreshUser, userRequest } = useCommonContext();
  const [isMainWallet, setIsMainWallet] = useState(false);

  const [err, setErr] = useState();
  const location = useLocation();

  //console.log("user is", user);
  const selfqrValue =
    instId && instId
      ? `upi://pay?pa=ipay.133876.` +
        instId +
        "@icici" +
        `&pn=${user && user.establishment}` +
        "&cu=INR"
      : "";

  // ######################################
  // W2 TO W1 TRANSFER API CALL ...........
  // ######################################
  const handleW2ToW1Transfer = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = {
      amount: form.w2_amount.value,
      pf: "WEB",
      latitude: userLat,
      longitude: userLong,
    };

    if (validateApiCall()) {
      postFormData(
        ApiEndpoints.W2TOW1_TRANSFER,
        data,
        setRequest,
        (res) => {
          okSuccessToast(res.data.message);
          setWalletTransferErrMsg("");
          document.getElementById("w2_amount").value = "";
          document.getElementById("w2_amount").focused = "off";
          refreshUser();
          getRecentData();
          setErr("");
        },
        (err) => {
          setErr("");
          if (
            err.response.data.message.amount &&
            err.response.data.message.amount
          ) {
            setWalletTransferErrMsg(err.response.data.message.amount);
          } else {
            setWalletTransferErrMsg("");
            apiErrorToast(err);
            refreshUser();
            getRecentData();
          }
        }
      );
    } else {
      setErr("");
      const error = {
        message: "Kindly wait some time before another request",
      };
      setErr(error);
    }
  };

  // useEffect(() => {
  //   getRecentData();
  // }, []);

  const handleOpen = () => {
    // const timer = setTimeout(() => {
    if (authCtx?.isLoggedIn) refreshUser();
    // }, 30000);
    // return () => clearTimeout(timer);
  };

  // ############################################
  // TRANSFER CARDS COMPONENT HANDLING FUNCTIONS
  // ############################################
  // const handleWalletTransfer = () => {
  //   if (showWalletTransfer && showWalletTransfer) {
  //     setShowWalletTransfer(!showWalletTransfer);
  //   }
  // };
  // const handleBankTransfer = () => {
  //   if (showBankTransfer && showBankTransfer) {
  //     setShowBankTransfer(!showBankTransfer);
  //   }
  // };
  // const handleSendMoneyView = () => {
  //   if (showSendMoney && showSendMoney) {
  //     setshowSendMoney(!showSendMoney);
  //   }
  // };
  const closeAllModals = () => {
    setShowWalletTransfer(false);
    setShowBankTransfer(false);
    setshowSendMoney(false);
  };

  const handleSendMoneyView = () => {
    closeAllModals();
    setshowSendMoney(true);
  };

  const handleWalletTransfer = () => {
    closeAllModals();
    setShowWalletTransfer(true);
  };

  const handleBankTransfer = () => {
    closeAllModals();
    setShowBankTransfer(true);
  };

  const handleWalletToggle = () => {
    setIsMainWallet(!isMainWallet);
  };
  return (
    <>
      {user.aggreement === 1 ? (
        <Box
          // className="bubble-bottom"
          sx={{
            mt: 2,
            px: 1,
            borderRadius: "10px",
            // backgroundImage: "linear-gradient(135deg, #4A90E2, #8E44AD)", // Gradient background
            // boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
            position: "relative", // Needed for layout
            backgroundImage: `url(${recent})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxShadow: "0 6px 12px rgba(26, 91, 172, 0.2)",
            border :"1px solid  rgba(26, 91, 172, 0.2)"
            // height: "130px", // Example height

            // clipPath: `polygon(0% 0%, 100% 0%, 100% 75%, 59% 75%, 48% 88%, 36% 76%, 0% 75%)`,
          }}
        >
          <Box
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "700",
              fontSize: "23px",
              position: "relative",
              color: "#111111",
            }}
          >
            Widgets
          </Box>

          <Grid
            item
            lg={4}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                mb: 1,
              }}
            >
              {/* <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}> */}

              {(user.role === "Ad" ||
                user.role === "Dd" ||
                user.role === "Md") && (
                  <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px", 
                   
                    borderRadius: "8px", 

                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "scale(1.05)", 
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)", 
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      color: "#000", 
                      backgroundColor: "#BBDEFB", 
                      borderRadius: "10px", 
                      padding: "6px",
                      border: "1px solid #90CAF9",
                      transition: "background-color 0.3s, transform 0.3s",
                      "&:hover": {
                        backgroundColor: "#64B5F6", 
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={() => {
                      setshowSendMoney(!showSendMoney);
                      handleSendMoneyView();
                    }}
                  >
                    <Tooltip title="Send Money" placement="top">
                      <CallMadeIcon
                      
                          sx={{ color: "#1A5BAC" }}
                    
                        onClick={() => setshowSendMoney(false)}
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
                
                // <SendMoney />
              )}

              {/* <AddBalanceViaPG /> */}
              {/* </Box> */}
              {/* <Mount
            visible={
              user && user.instId && selfqrValue !== "" && user.upi_qr === 1
            }
          >
            <Box className="hover-zoom">
              <IconButton
                className="hover-zoom"
                sx={{ color: "#E49B0F" }}
                onClick={() => {
                  setOpen(true);
                  handleWalletTransfer();
                  handleBankTransfer();
                }}
              >
                <Tooltip title="QR" placement="left">
                  <QrCode2Icon className="hover-white" />
                </Tooltip>
              </IconButton>
            </Box>
          </Mount> */}
              {/* <Mount visible={user.instId}>
            <OutletRegistration
              btn={
                <Box className="hover-zoom">
                  <IconButton className="hover-zoom">
                    <Tooltip
                      title="QR"
                      placement="left"
                      sx={{ color: "#E49B0F" }}
                    >
                      <QrCode2Icon className="hover-white" />
                    </Tooltip>
                  </IconButton>
                </Box>
              }
            />
          </Mount> */}
{user.role !== "Ad" && user.role !== "Md" ? (
  <>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px",
        borderRadius: "8px",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <IconButton
        sx={{
          color: "#000",
          backgroundColor: "#BBDEFB",
          borderRadius: "10px",
          padding: "6px",
          border: "1px solid #90CAF9",
          transition: "background-color 0.3s, transform 0.3s",
          "&:hover": {
            backgroundColor: "#64B5F6",
            color: "#FFFFFF",
          },
        }}
        onClick={() => {
          setShowBankTransfer(!showBankTransfer);
          handleWalletTransfer();
        }}
      >
        <Tooltip title="Bank Transfer" placement="top">
          <AccountBalanceIcon sx={{ color: "#1A5BAC" }} />
        </Tooltip>
      </IconButton>
    </Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px",
        borderRadius: "8px",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <IconButton
        sx={{
          color: "#000",
          backgroundColor: "#BBDEFB",
          borderRadius: "10px",
          padding: "6px",
          border: "1px solid #90CAF9",
          transition: "background-color 0.3s, transform 0.3s",
          "&:hover": {
            backgroundColor: "#64B5F6",
            color: "#FFFFFF",
          },
        }}
        onClick={() => {
          setShowWalletTransfer(!showWalletTransfer);
          handleBankTransfer();
        }}
      >
        <Tooltip title="W2 to W1 Transfer" placement="top">
          <AccountBalanceWalletIcon sx={{ color: "#1A5BAC" }} />
        </Tooltip>
      </IconButton>
    </Box>
  </>
) : null}

              {/* <Box className="hover-zoom">
            <IconButton sx={{ color: "#fff" }}>
              <Tooltip title="Refund" placement="top">
                <Icon
                  icon="ri:reset-left-fill"
                  className="hover-white"
                  width="24"
                  height="24"
                />
              </Tooltip>
            </IconButton>
          </Box> */}
            </Box>
          </Grid>

          {/* QR Modal Section */}
          <Mount visible={showQr}>
            <Card
              id="qrDrop"
              sx={{
                mt: 2,
                p: { md: 1, sm: 1, xs: 2 },
                width: "100%",
              }}
              className="position-relative"
            >
              <IconButton className="top-right-position" sx={{ color: "#111" }}>
                <HighlightOffRoundedIcon
                  className="hover-red"
                  onClsick={() => setShowQr(false)}
                />
              </IconButton>

              <Box style={{ fontWeight: "bold" }}>{user.name}</Box>
              <Box style={{ fontSize: "10px" }}>{selfqrValue}</Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <QRCode value={selfqrValue} size={156} />
              </Box>

              <Box style={{ fontWeight: "bold" }}>Scan this code & pay me</Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                }}
              >
                <img src={upiWeb} alt="upi apps" />
              </Box>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "11px",
                  fontWeight: "600",
                  color: "#676970",
                  p: 2.2,
                  pb: 0,
                }}
              >
                *Note: This Payment will be added to W2
              </Typography>
            </Card>
          </Mount>

          {/* Wallet Transfer Modal Section */}
          <Mount visible={showWalletTransfer}>
            <Grid
              id="qrDrop"
              sx={{
                marginTop: "12px",
                px: 2,
                pt: 2,
                backgroundColor: "#",
                m: 1.5,
              }}
              className="position-relative card-css"
            >
              <IconButton className="top-right-position">
                <HighlightOffRoundedIcon
                  className="hover-red"
                  onClick={() => {
                    setShowWalletTransfer(false);
                    setErr("");
                    setWalletTransferErrMsg("");
                  }}
                />
              </IconButton>
              <Loader loading={request} circleBlue />

              <Typography
                sx={{ fontWeight: "bold", width: "100%", textAlign: "left" }}
              >
                W2 to W1 Transfer
              </Typography>

              <Box
                component="form"
                id="walletTransfer"
                validate
                autoComplete="off"
                onSubmit={handleW2ToW1Transfer}
              >
                <FormControl sx={{ width: "100%", mt: 1 }}>
                  <TextField
                    autoComplete="off"
                    label="Enter Amount"
                    id="w2_amount"
                    type="number"
                    sx={{ backgroundColor: "#" }}
                    onChange={() => {
                      setWalletTransferErrMsg("");
                    }}
                    required
                    size="small"
                    onKeyDown={(e) => {
                      if (e.key === "+" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Box>

              <Box sx={{ width: "100%", textAlign: "right" }}>
                {err && (
                  <Typography sx={{ fontSize: "12px", color: "#4E5555" }}>
                    {err?.message}
                  </Typography>
                )}
                {walletTransferErrMsg && (
                  <Typography sx={{ fontSize: "12px", color: "#4E5555" }}>
                    {walletTransferErrMsg}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    my: 1,
                    textTransform: "capitalize",
                    mt: 1,
                  }}
                  className="otp-hover-purple"
                  form="walletTransfer"
                  type="submit"
                  disabled={request}
                >
                  Proceed
                </Button>
              </Box>
            </Grid>
          </Mount>

          {/* Bank Transfer Modal Section */}
          <Mount visible={showBankTransfer}>
            <BankTransfer
              showBankTransfer={showBankTransfer}
              setShowBankTransfer={setShowBankTransfer}
            />
          </Mount>
          <Mount visible={showSendMoney}>
            <SendMoney
              showSendMoney={showSendMoney}
              setshowSendMoney={setshowSendMoney}
            />
          </Mount>
          <QRWarningModal
            open={open}
            showQr={showQr}
            setOpen={setOpen}
            setShowQr={setShowQr}
          />
        </Box>
      ) : null}
    </>
  );
};

export default RightSideCard;
