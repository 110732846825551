import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SideBarContext from "../store/SideBarContext";
import { Box } from "@mui/material";
import AuthContext from "../store/AuthContext";

const NavItemComponent = ({
  item,
  open,
  index,
  setOpen,
  handleDrawerToggle,
  mobileOpen,
}) => {
  const authCtx = useContext(AuthContext);
  const sidebarCtx = useContext(SideBarContext);
  const iconColor = authCtx.iconColor; // Icon color from context
  const setActiveIndex = sidebarCtx.setActiveIndex;
  const location = useLocation();
  const user = authCtx.user;
  const currentPath = location.pathname;
  const setCurrentView = authCtx.setCurrentView;
  let isCurrentActive = currentPath === item?.to;

  if (
    currentPath.includes("/admin/accountStatement") &&
    item?.to === "/admin/accounts"
  ) {
    isCurrentActive = true;
  } else if (
    currentPath.includes("/admin/bankStatement") &&
    item?.to === "/admin/banks"
  ) {
    isCurrentActive = true;
  }

  const [iconDynamicColor, setIconDynamicColor] = useState(iconColor);

  useEffect(() => {
    console.log("Icon color changed:", iconColor); // Check if the value is changing
    setIconDynamicColor(iconColor);
  }, [iconColor]);

  // Hide the NavItem if user agreement is not accepted
  if (
    user?.aggreement !== 1 &&
    !(user.role === "Admin" || user.role === "Asm" || user.role === "Zsm")
  ) {
    return null;
  }

  if (
    user.role === "Admin" &&
    ((item.title === "Fund Requests" && user?.c_req !== 1) ||
      (item.title === "Transactions" && user?.transactions !== 1) ||
      (item.title === "Users" && user?.users !== 1) ||
      (item.title === "Banking" && user?.banking !== 1) ||
      (item.title === "Messages" && user?.messages !== 1) ||
      (item.title === "Services" && user?.services !== 1) ||
      (item.title === "Schemes" && user?.scheme !== 1) ||
      (item.title === "Risk" && user?.risk !== 1) ||
      (item.title === "Employees" && user?.emp !== 1) ||
      (item.title === "Invoices" && user?.invoice !== 1) ||
      (item.title === "Virtual Accounts" && user?.v_account !== 1))
  ) {
    return null;
  }
  
  if (
    (user.role === "Ret" || user.role === "Dd") &&
    (
      (item?.title === "Fund Request" && user?.c_req !== 1) ||
      (item?.title === "Money Transfer" && user?.dmt1 !== 1 && user?.dmt2 !== 1) ||
      (item?.title === "Wallet Transfer" && user?.dmt4 !== 1) ||
      (item?.title === "Payments" && user?.st !== 1)||
      (item?.title === "BBPS" && user?.bbps !== 1) 
      ||
      (item?.title === "Aeps" && user?.aeps !== 1) 

    )
  ) {
    return null;
  }
  

  const handleItemClick = () => {
    setActiveIndex({
      index: index,
      subIndex: -1,
    });
    if (mobileOpen) {
      handleDrawerToggle();
    } else setOpen(false);
    if (item.to === "/customer/services") {
      setCurrentView(null);
    }
  };

  return (
    <div>
      <ListItem
        key={item.title}
        disablePadding
        sx={{
          display: "block",
          color: "#fff",
        }}
        onClick={handleItemClick}
      >
        <NavLink
          to={item.to}
          key={item.to}
          target={item.title === "IRCTC" && "_blank"}
          style={({ isActive }) => {
            return {
              display: "block",
              margin: "0.2rem 0",
              textDecoration: "none",
              padding: open ? "0px 8px 0px 8px" : "0px 8px 0px 8px",
            };
          }}
        >
          <ListItemButton
            sx={{
              justifyContent: open ? "initial" : "center",
              backgroundColor: isCurrentActive ? iconColor : "",
              backdropFilter: isCurrentActive ? "blur(5px)" : "",
              border: isCurrentActive ? "1px solid #000" : "",
              "&:hover": {
                backgroundColor: isCurrentActive ? iconColor : "#F18D18",
                color: "white",
              },
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
            }}
          >
            <Box
              sx={{
                mr: open ? 2 : "auto",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                width="22px"
                height="30px"
                src={item.icon}
                alt=""
                style={{
                  filter: isCurrentActive
                    ? "invert(100%) brightness(1000%)"
                    : "brightness(0%)",
                }}
              />
            </Box>

            <ListItemText
              className="menu-title"
              primary={item.title}
              disableTypography
              sx={{
                opacity: open ? 1 : 0,
                color: isCurrentActive ? "#FFF" : "#fff",
                fontSize: "16px",
                fontFamily: "Montserrat, sans-serif",
                "&:hover": {
                  color: isCurrentActive ? "#FFF" : "#fff",
                },
              }}
            />
          </ListItemButton>
        </NavLink>
      </ListItem>
    </div>
  );
};

export default NavItemComponent;
