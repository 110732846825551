import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdminCreateQr from '../modals/AdminCreateQr';
import AsmProductSaleModal from '../modals/admin/AsmProductSaleModal';
import AdminCreateVirtualAcct from '../modals/AdminCreateVirtualAcct';
import AdminChargesForApiUsers from '../modals/AdminChargesForApiUsers';
import AdminDeletesUserModal from '../modals/AdminDeletesUserModal';

const DataRequestComponent = ({ row, refresh, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'data-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="data-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {user && user.role === "Admin" && (
          <>
            <MenuItem>
              <AdminCreateQr row={row} refresh={refresh} />
            </MenuItem>
            <Divider sx={{ backgroundColor: 'blue', height: 2 }} />
            <MenuItem>
              <AdminCreateVirtualAcct row={row} refresh={refresh} />
            </MenuItem>
            <Divider sx={{ backgroundColor: 'blue', height: 2 }} />
            <MenuItem>
              <AsmProductSaleModal
                role={row.role}
                name={row.name}
                id={row.id}
                amount={<span style={{ color: 'black' }}>Performance Report</span>}
                usedInUserTable
              />
            </MenuItem>
          </>
        )}
        {(row?.role === "Api" ) && (
          <>
            <Divider sx={{ backgroundColor: 'blue', height: 2 }} />
            <MenuItem>
              <AdminChargesForApiUsers row={row} />
            </MenuItem>
          </>
        )}
        {user?.id.toString() === "1" && (
          <>
            <Divider sx={{ backgroundColor: 'blue', height: 2 }} />
            <MenuItem>
              <AdminDeletesUserModal row={row} refresh={refresh} />
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default DataRequestComponent;
