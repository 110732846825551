import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  // Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import ApiPaginate from "../component/ApiPaginate";
import ApiEndpoints from "../network/ApiEndPoints";
import CachedIcon from "@mui/icons-material/Cached";
import { useState } from "react";
import { CustomStyles } from "../component/CustomStyle";
import { datemonthYear, yyyymmdd } from "../utils/DateUtils";
import ExcelUploadModal from "../modals/ExcelUploadModal";
import { get } from "../network/ApiController";
import moment from "moment";
import { json2Csv, json2Excel } from "../utils/exportToExcel";
import { apiErrorToast } from "../utils/ToastUtil";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import LaptopIcon from "@mui/icons-material/Laptop";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import { DateRangePicker } from "rsuite";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

// ### NEW TRANSACTION VIEW CODE ####
import { primaryColor } from "../theme/setThemeColor";
import useCommonContext from "../store/CommonContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import { USER_ROLES } from "../utils/constants";
import predefinedRanges from "../utils/predefinedRanges";
import CommonStatus from ".././component/CommonStatus";
import StatusDisplay from "../StatusDisplay";

let refresh;
let handleCloseModal;
const prefilledQuery = "type_txn=SALE";
function refreshFunc(setQueryParams) {
  setQueryParams(prefilledQuery);
  if (refresh) refresh();
}
const AdSaleView = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.user;
  const role = user?.role;
  const navigate = useNavigate();
  const { setChooseInitialCategoryFilter } = useCommonContext();

  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState("");
  const [sumData, setSumData] = useState(false);
  const [request, setRequest] = useState(false);
  const [noOfResponses, setNoOfResponses] = useState(0);
  const [filterValues, setFilterValues] = useState({ date: {}, dateVal: null });
  const [isBig, setIsBig] = React.useState(
    window.innerWidth < 900 ? false : true
  );

  const changeApply = () => {
    if (window.innerWidth < 900) setIsBig(false);
    if (window.innerWidth > 900) setIsBig(true);
  };
  useEffect(() => {
    window.addEventListener("resize", changeApply);
    return () => {
      window.removeEventListener("resize", changeApply);
    };
  }, []);
  const getExcel = () => {
    get(
      ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Excel(
          `My Sale Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const getCsv = () => {
    get(
      ApiEndpoints.GET_TRANSACTIONS,
      `${
        query
          ? query + `&page=1&paginate=10&export=1`
          : `page=1&paginate=10&export=1`
      }`,
      setRequest,
      (res) => {
        const apiData = res.data.data;
        const newApiData = apiData.map((item) => {
          const created_at = moment(item.created_at).format("DD-MM-YYYY");
          const time_updated_at = moment(item.updated_at).format("LTS");
          return { ...item, created_at, time_updated_at };
        });
        json2Csv(
          `My Sale Transactions ${moment(new Date().toJSON()).format(
            "Do MMM YYYY"
          )} | ${moment(new Date().toJSON()).format("hh:mm a")}`,
          JSON.parse(JSON.stringify(newApiData && newApiData))
        );
        handleCloseModal();
      },
      (err) => {
        apiErrorToast(err);
      }
    );
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => datemonthYear(row.created_at),
    },
    // {
    //   name: "Platform",
    //   selector: (row) => row.platform,
    //   width: "80px",
    // },
    {
      name: "Platform",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div>
            {row.platform === "APP" ? (
              <Tooltip title="APP">
                <InstallMobileIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "WEB" ? (
              <Tooltip title="WEB">
                <LaptopIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "ANDROID" ? (
              <Tooltip title="ANDROID">
                <AndroidIcon fontSize="small" />
              </Tooltip>
            ) : row.platform === "IOS" ? (
              <Tooltip title="IOS">
                <AppleIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="API">
                <SyncAltIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          {/* <div className="fw-bold">{row.platform}</div> */}
        </div>
      ),
      center: false,
      width: "80px",
    },

    {
      name: "Info",
      selector: (row) => row.info,
    },
    {
      name: "Service",
      selector: (row) => (
        <div style={{ textAlign: "left", fontSize: "14px" }}>
          {row.operator}
        </div>
      ),
      center: false,
      wrap: true,
    },

    {
      name: "Amount",
      selector: (row) => Number(row.amount).toFixed(2),
    },
    {
      name: "Net Amount",
      selector: (row) => (
        <div>
          {row.txn_type && row.txn_type === "CR" ? "+" : "-"}
          {Number(row.net_amount).toFixed(2)}
        </div>
      ),
    },
    {
      name: "Wallet Balance",
      selector: (row) => (
        <div>
          <div>{Number(row.w1).toFixed(2)}</div>
          {/* <div>{Number(row.w2).toFixed(2)}</div> */}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <CommonStatus
              status={row.status}
              approvedStatusText="Success"
              pendingStatusText="Pending"
              rejectedStatusText="Failed"
              refundStatusText="Refund"
              fontSize="13px"
            />
          </Box>
        </>
      ),
    },
  ];

  const searchOptions = [{ field: "Info", parameter: "info" }];
  return (
    <>
      {user?.aggreement === 1 ? (
        <Box>
          <Grid container>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
                justifyContent: "end",
                alignItems: "center",
                flexDirection: { md: "row" },
                pr: 1,
              }}
            >
              {/* back button */}
              <Button
                size="small"
                className="otp-hover-purple mb-2"
                sx={{
                  color: primaryColor(),
                }}
                onClick={() => {
                  setChooseInitialCategoryFilter(false);
                  if (role === USER_ROLES.AD) {
                    navigate("/ad/transactions");
                  } else if (role === USER_ROLES.MD) {
                    navigate("/md/transactions");
                  } else {
                  }
                }}
              >
                <KeyboardBackspaceIcon fontSize="small" /> Back
              </Button>
              <ExcelUploadModal
                twobuttons="Download Csv"
                btn
                request={request}
                getExcel={getExcel}
                getCsv={getCsv}
                noOfResponses={noOfResponses}
                setQuery={setQuery}
                handleCloseCB={(closeModal) => {
                  handleCloseModal = closeModal;
                }}
              />
              <Tooltip title="refresh">
                <IconButton
                  aria-label="refresh"
                  sx={{ color: "#0F52BA" }}
                  onClick={() => {
                    refreshFunc(setQuery);
                  }}
                >
                  <CachedIcon className="refresh-purple" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {/* <ExcelUploadModal
          twobuttons="Download Csv"
          btn
          request={request}
          getExcel={getExcel}
          getCsv={getCsv}
          noOfResponses={noOfResponses}
          setQuery={setQuery}
          handleCloseCB={(closeModal) => {
            handleCloseModal = closeModal;
          }}
        />
        <Tooltip title="refresh">
          <IconButton
            aria-label="refresh"
            color="success"
            onClick={() => {
              refreshFunc(setQuery);
            }}
          >
            <CachedIcon className="refresh-purple" />
          </IconButton>
        </Tooltip> */}
          </Box>
          <Grid sx={{ pr: { xs: 1.3, lg: 0 } }}>
            <ApiPaginateSearch
              showSearch
              // totalCard={
              //   <>
              //     <StatusDisplay sumData={sumData} setSumData={setSumData} />
              //   </>
              // }
              actionButtons={
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "end", xs: "start" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mx: 2 }}
                  >
                    <DateRangePicker
                      placement={isBig ? "leftStart" : "auto"}
                      showOneCalendar
                      placeholder="Date"
                      size="xs"
                      cleanable
                      value={filterValues.dateVal}
                      ranges={predefinedRanges}
                      onChange={(value) => {
                        const dateVal = value;
                        const dates = {
                          start: dateVal && dateVal[0],
                          end: dateVal && dateVal[1],
                        };
                        setFilterValues({
                          ...filterValues,
                          date: {
                            start: yyyymmdd(dates.start),
                            end: yyyymmdd(dates.end),
                          },
                          dateVal,
                        });
                        if (dateVal) {
                          setQuery(
                            `type_txn=SALE&start=${yyyymmdd(
                              dateVal[0]
                            )}&end=${yyyymmdd(dateVal[1])}`
                          );
                        } else {
                          setQuery(`type_txn=SALE`);
                        }
                      }}
                      // disabledDate={afterToday()}
                    />
                  </Box>

                  <ExcelUploadModal
                    twobuttons="Download Csv"
                    btn
                    request={request}
                    getExcel={getExcel}
                    getCsv={getCsv}
                    noOfResponses={noOfResponses}
                    setQuery={setQuery}
                    handleCloseCB={(closeModal) => {
                      handleCloseModal = closeModal;
                    }}
                  />
                  <Tooltip title="refresh">
                    <IconButton
                      aria-label="refresh"
                      sx={{ color: "#0F52BA" }}
                      onClick={() => {
                        refreshFunc(setQuery);
                      }}
                    >
                      <CachedIcon className="refresh-purple" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
              // backButton={
              //   <Button
              //     size="small"
              //     className="otp-hover-purple mb-2"
              //     sx={{
              //       color: primaryColor(),
              //     }}
              //     onClick={() => {
              //       setChooseInitialCategoryFilter(false);
              //       if (role === USER_ROLES.AD) {
              //         navigate("/ad/transactions");
              //       } else if (role === USER_ROLES.MD) {
              //         navigate("/md/transactions");
              //       } else {
              //       }
              //     }}
              //   >
              //     <KeyboardBackspaceIcon fontSize="small" /> Back
              //   </Button>
              // }
              apiEnd={ApiEndpoints.GET_TRANSACTIONS}
              searchOptions={searchOptions}
              columns={columns}
              apiData={apiData}
              query={query}
              setSumData={setSumData}
              setQuery={setQuery}
              tableStyle={CustomStyles}
              setApiData={setApiData}
              ExpandedComponent=""
              queryParam={query ? query : ""}
              returnRefetch={(ref) => {
                refresh = ref;
              }}
              responses={(val) => {
                setNoOfResponses(val);
              }}
              prefilledQuery={prefilledQuery}
            />
          </Grid>
          {/* <ApiPaginate
          apiEnd={ApiEndpoints.GET_TRANSACTIONS}
          columns={columns}
          apiData={apiData}
          tableStyle={CustomStyles}
          setApiData={setApiData}
          ExpandedComponent=""
          queryParam={query ? query : ""}
          returnRefetch={(ref) => {
            refresh = ref;
          }}
          responses={(val) => {
            setNoOfResponses(val);
          }}
        /> */}
        </Box>
      ) : (
        <Box>
          {/* <Grid
                     item
                     className="card-css"
                     sx={{ p: 3, mt: 2, mb: { md: 0, xs: 5 } }}
                   >
                     <h5>To use our services, please sign the agreement</h5>
                     <br />
                     {user.role === "Ret" || user.role === "Dd" ? (
                       <a href="/service" target="_blank" rel="noopener noreferrer">
                         Click here
                       </a>
                     ) : (
                       <a
                         href="/distributoragreement"
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         Click here
                       </a>
                     )}
                   </Grid> */}
          <Grid
            item
            className="card-css"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              p: 4,

              position: "absolute",
              top: "50%",
              left: "40%",
              transform: "translate(-50%, -50%)",

              backgroundColor: " rgb(73, 132, 200) ",
              borderRadius: "10px",
              boxShadow: 3,
              maxWidth: "600px",

              height: "300px",
            }}
          >
            <Box sx={{ textAlign: "center", color: "#fff", width: "100%" }}>
              <h5
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginBottom: "20px",
                  letterSpacing: "1px",
                }}
              >
                To use our services, please sign the agreement
              </h5>

              <br />
              {user.role === "Ret" || user.role === "Dd" ? (
                <a
                  href="/service"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff", // White color for the link
                    fontSize: "18px", // Slightly larger font for the link
                    fontWeight: "500", // Medium weight for the link
                    textDecoration: "none", // No underline by default
                    backgroundColor: "rgb(220, 42, 113)", // Dark blue background for a button effect
                    padding: "8px 20px", // Padding inside the button
                    borderRadius: "30px", // Rounded button shape
                    display: "inline-block",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "rgb(26, 32, 38)"; // Darken blue on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "rgb(38, 50, 56)"; // Revert to original color
                  }}
                >
                  Click here
                </a>
              ) : (
                <a
                  href="/distributoragreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#fff", // White color for the link
                    fontSize: "18px", // Slightly larger font for the link
                    fontWeight: "500", // Medium weight for the link
                    textDecoration: "none", // No underline by default
                    backgroundColor: "rgb(148, 135, 22)", // Light yellow background for a button effect
                    padding: "8px 20px", // Padding inside the button
                    borderRadius: "30px", // Rounded button shape
                    display: "inline-block", // Inline-block to maintain button structure
                    transition: "background-color 0.3s", // Smooth background color change
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "rgb(255, 193, 7)"; // Darken yellow on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "rgb(255, 235, 59)"; // Revert to original color
                  }}
                >
                  Click here
                </a>
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AdSaleView;
