import React, { useState } from "react";
import { apiErrorToast, okSuccessToast } from "../../utils/ToastUtil";
import { get, postJsonData } from "../../network/ApiController";
import ApiEndpoints from "../../network/ApiEndPoints";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Mount from "../Mount";
import { useContext } from "react";
import AuthContext from "../../store/AuthContext";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import Loader from "../loading-screen/Loader";
import { PATTERNS } from "../../utils/ValidationUtil";
import PinInput from "react-pin-input";
import ResetMpin from "../../modals/ResetMpin";
import useCommonContext from "../../store/CommonContext";
import { validateApiCall } from "../../utils/LastApiCallChecker";
import ModalFooter from "../../modals/ModalFooter";

export const SendMoney = ({ showBankTransfer, setshowSendMoney, refresh }) => {
  const authCtx = useContext(AuthContext);
  const refreshUser = authCtx.refreshUser;
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(false);
  const [mpin, setMpin] = useState("");
  const [err, setErr] = useState();
  const [isMobv, setIsMobv] = useState(true);
  const { getRecentData } = useCommonContext();
  const [usernameAdded, setUsernameAdded] = useState(false);
  const [getUser, setGetUser] = useState("");
  const userLat = authCtx.location.lat;
  const userLong = authCtx.location.long;
  const fetchUser = (mobile) => {
    get(
      ApiEndpoints.GET_USER_BY_USERNAME,
      `username=${mobile}`,
      setRequest,
      (res) => {
        if (res && res.data && res.data) {
          setGetUser(res.data.data);
          setUsernameAdded(true);
        } else setGetUser();
      },
      (error) => {
        apiErrorToast(error);
      }
    );
  };

  const handleClose = () => {
    setUsernameAdded(false);
    setErr("");
    setMpin("");
    setGetUser("");
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let amt = document.getElementById("amount").value;
    const data = {
      to_id: getUser && getUser.id,
      pf: "WEB",
      req_type: "OTHER",
      amount: amt,
      mpin: mpin,
      latitude: userLat,
      longitude: userLong,
    };
    if (mpin !== "" && validateApiCall()) {
      postJsonData(
        ApiEndpoints.MONEY_TRANSFER,
        data,
        setRequest,
        (res) => {
          getRecentData();
          okSuccessToast("Amount Transfer Successfull");
          handleClose();
          setMpin("");
          setErr("");
          if (refresh) refresh();
        },
        (error) => {
          setMpin("");
          setErr("");
          if (error && error) {
            if (error.response.data.message === "Invalid M Pin") {
              setErr(error.response.data);
            } else {
              apiErrorToast(error);
            }
          }
        }
      );
    } else if (amt === "") {
      setErr("");
      const error = {
        message: "The amount field is required.",
      };
      setErr(error);
    } else if (mpin === "") {
      setErr("");
      setMpin("");
      const error = {
        message: "MPIN required",
      };
      setErr(error);
    } else {
      setErr("");
      setMpin("");
      const error = {
        message: "Kindly wait some time before another request",
      };
      setErr(error);
    }
  };

  return (
    <Grid
      className="card-css position-relative"
      id="bank"
      sx={{
        marginTop: "12px",
        px: 0.5,
        pt: 2,
        pb: 0.5,
        m: 1,
      }}
    >
      {/* CLOSE BUTTON */}
      <IconButton className="top-right-position">
        <HighlightOffRoundedIcon
          className="hover-red"
          onClick={() => {
            setshowSendMoney(false);
          }}
        />
      </IconButton>
      <>
        <Box>
          <Loader loading={request} />
          <Box
            component="form"
            id="money_transfer"
            validate
            autoComplete="off"
            // onSubmit={handleSubmit}
            sx={{
              "& .MuiTextField-root": { m: 2 },
            }}
          >
            <Grid container sx={{ pt: 1 }}>
              <Grid item md={12} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    autoComplete="off"
                    label="Mobile"
                    id="mobile"
                    size="small"
                    type="tel"
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                      maxLength: 10,
                    }}
                    onKeyDown={(e) => {
                      if ((e.which >= 65 && e.which <= 90) || e.key === "+") {
                        e.preventDefault();
                      }
                      if (e.target.value.length === 10) {
                        if (e.key.toLowerCase() !== "backspace") {
                          e.preventDefault();
                        }

                        if (e.key.toLowerCase() === "backspace") {
                        }
                      }
                    }}
                    error={!isMobv}
                    helperText={!isMobv ? "Enter valid Mobile" : ""}
                    onChange={(e) => {
                      setIsMobv(PATTERNS.MOBILE.test(e.target.value));
                      if (e.target.value === "") setIsMobv(true);
                      if (PATTERNS.MOBILE.test(e.target.value)) {
                        if (e.target.value.length === 10) {
                          fetchUser(e.target.value);
                        } else {
                          setUsernameAdded(false);
                          setGetUser("");
                        }
                      }
                    }}
                    required
                  />
                </FormControl>
              </Grid>
              {usernameAdded && usernameAdded ? (
                <Grid item md={12} xs={12}>
                  <Box
                    id="userData"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        autoComplete="off"
                        label="Name"
                        id="name"
                        size="small"
                        defaultValue={getUser && getUser.name}
                        disabled
                        required
                      />
                    </FormControl>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        autoComplete="off"
                        label="Establishment"
                        id="name"
                        size="small"
                        defaultValue={getUser && getUser.establishment}
                        disabled
                        required
                      />
                    </FormControl>
                  </Box>
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={12} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    autoComplete="off"
                    label="Amount"
                    id="amount"
                    size="small"
                    type="number"
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        max: 500000,
                        min: 100,
                      },
                    }}
                    required
                    onKeyDown={(e) => {
                      if (e.key === "+" || e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControl>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    M-PIN
                  </div>
                  <PinInput
                    length={6}
                    focus
                    type="password"
                    onChange={(value, index) => {
                      if (err !== "") {
                        setErr("");
                      }
                      setMpin(value);
                    }}
                    inputMode="text"
                    regexCriteria={/^[0-9]*$/}
                  />

                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", mt: 1 }}
                  >
                    <ResetMpin variant="text" />
                  </Grid>
                </FormControl>
              </Grid>

              {err && err && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    fontSize: "12px",
                    px: 2,
                    color: "#FF0000",
                  }}
                >
                  {err.message && err.message && (
                    <div>{err && err.message}</div>
                  )}

                  {err.data && err.data && (
                    <div className="blink_text">
                      Attempts remaining:{err && 5 - Number(err.data)}
                    </div>
                  )}
                </Box>
              )}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 1,
              }}
            >
              <Button
                onClick={handleSubmit}
                variant="small"
                color="primary"
                sx={{
                  background: "#1877F2",
                  "&:hover": {
                    backgroundColor: "#00308F",
                  },
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s, transform 0.2s",
                  "&:active": {
                    transform: "scale(0.98)",
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    </Grid>
  );
};
