import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import robotoBold from "../fonts/Roboto-Bold.ttf";
import {
  Logo,
  cBottom,
  cTop,
  impsguruStamp,
  loginPage1,
} from "../iconsImports";
// Registering custom font
Font.register({
  family: "roboto-bold",
  src: robotoBold,
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#EAF2FF", // Set the background color here
    position: "relative",
    padding: "20px", // Ensure content doesn't touch edges
  },
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  imageTop: {
    position: "absolute",
    top: "0px",
    right: "10px",
    width: "400px",
  },
  logoCenter: {
    position: "absolute",
    top: "60px",
    left: "20%",
    transform: "translateX(-50%)",
    width: "160px",
  },
  certificateTitle: {
    fontSize: "50px",
    textAlign: "center",
    letterSpacing: "5px",
    color: "#000",
    marginTop: "90px",
  },
  subText: {
    fontSize: "12px",
    textAlign: "center",
    letterSpacing: "0.5px",
    marginBottom: "10px",
  },
  separatorLine: {
    height: "2px",
    backgroundColor: "#000",
    width: "80%",
    marginLeft: "10%",
    marginTop: "10px",
    marginBottom: "20px",
  },
  boldText: {
    fontFamily: "roboto-bold",
  },
  bodyText: {
    fontSize: "12px",
    letterSpacing: "0.4px",
    textAlign: "justify",
    marginVertical: "6px",
    opacity: 0.7,
    lineHeight: 2.3,
    padding: 15,
  },
  footerText: {
    fontSize: "12px",
    letterSpacing: "0.4px",
    textAlign: "center",
    opacity: 0.7,
  },
  imageBottom: {
    position: "absolute",
    bottom: "0px",
    left: "10px",
    width: "700px",
  },
  stampImage: {
    position: "absolute",
    bottom: "90px",
    right: "10px",
    width: "200px",
  },
});
const IrctcPdf = ({ user }) => {
  return (
    <Document>
      <Page style={styles.page} orientation="portrait" size="B5">
        {/* Top Decorative Image */}
        <Image src={cTop} style={styles.imageTop} />
        {/* Centered Logo */}
        <Image src={loginPage1} style={styles.logoCenter} />
        {/* Certificate Header */}
        <Text style={styles.certificateTitle}>CERTIFICATE</Text>
        <Text style={styles.subText}>AUTHORISED RAIL E-TICKETING CENTRE</Text>
        <Text style={styles.subText}>OF</Text>
        <Text style={styles.subText}>MOBIFAST SOLUTIONS PRIVATE LIMITED</Text>
        <View style={styles.separatorLine} />
        {/* Certificate Body */}
        <Text style={[styles.bodyText]}>
          This is to certify that{" "}
          <Text style={styles.boldText}>{user.establishment}</Text>, owned by{" "}
          <Text style={styles.boldText}>{user.name}</Text>, located at{" "}
          <Text style={styles.boldText}>
            {user.address} ,Contact No-{user.username} ,Email Id-{user.email}
          </Text>
          , is hereby authorized under Mobifast Solutions Private Limited under
          (Internet Cafe Scheme) to book Railway E-Tickets as per IRCTC
          E-Ticketing terms & conditions using agent id-
          <Text style={styles.boldText}>icimpsg00000</Text> through the IRCTC
          website{" "}
          <Text style={[styles.bodyText, { textDecoration: "underline" }]}>
            www.irctc.co.in
          </Text>{" "}
          for the general public .
          {/* <Text style={styles.boldText}>[Start Date]</Text> till{" "}
          <Text style={styles.boldText}>[End Date]</Text>. */}
        </Text>
        {/* <Text style={[styles.bodyText]}>
          Mobifast Solutions Pvt Ltd for providing Banking{" "}
          <Text style={styles.boldText}>Business Correspondent services</Text>{" "}
          during{" "}
          <Text style={styles.boldText}>
            FY 2023 - 2024
          </Text>.
        </Text> */}
        {/* Bottom Decorative Image */}
        <Image src={cBottom} style={styles.imageBottom} />
        {/* Stamp */}
        <Image src={impsguruStamp} style={styles.stampImage} />
      </Page>
    </Document>
  );
};
export default IrctcPdf;
