import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ApiPaginateSearch from "../component/ApiPaginateSearch";
import { useState } from "react";
// import { useContext } from "react";
// import AuthContext from "../store/AuthContext";
import { CustomStyles } from "../component/CustomStyle";
import RefreshComponent from "../component/RefreshComponent";
import ApiEndpoints from "../network/ApiEndPoints";
import { mt_tab_value } from "../utils/constants";
import { ddmmyy, dateToTime, datemonthYear } from "../utils/DateUtils";
import { currencySetter } from "../utils/Currencyutil";
import { useTheme } from "@mui/material/styles";

import CreateEditLimitAccount from "../component/accountLimits/CreateEditLimtAccount";
import AdminDeleteLimitedAccounts from "../component/accountLimits/AdminDeleteLimitedAccounts";

import CachedIcon from "@mui/icons-material/Cached";

import { TabPanel } from "@mui/lab";
import ApiPaginate from "../component/ApiPaginate";

let refresh;
function refreshFunc(setQueryParams) {
  setQueryParams("");
  if (refresh) refresh();
}
let refreshFilter;

const AdminAccountLimit = () => {
  const theme = useTheme();
  const [apiData, setApiData] = useState([]);
  const [query, setQuery] = useState();
  const [singleUser, setSingleUser] = useState(null);

  //   const authCtx = useContext(AuthContext);
  //   const user = authCtx.user;
  //   const role = user?.role;
  const [value, setValue] = useState(0);
  const [currentType, setCurrentType] = useState();

  const searchOptions = [
    { field: "AC Name", parameter: "acc_name" },
    { field: "AC Number", parameter: "acc_no" },
  ];
  const searchOptionsVa = [
    { field: "AC Name", parameter: "acc_name" },
    { field: "AC Number", parameter: "acc_no" },
  ];

  const columns = [
    {
      name: "Created/Updated",
      selector: (row) => (
        <>
          <div className="mb-2" style={{ textAlign: "left" }}>
            {ddmmyy(row.created_at)} {dateToTime(row.created_at)}
          </div>
          <div>
            {ddmmyy(row.updated_at)} {dateToTime(row.updated_at)}
          </div>
        </>
      ),
      width: "150px",
    },

    {
      name: "AC Name",
      selector: (row) => (
        <div style={{ textAlign: "left" }}>{row.acc_name}</div>
      ),
      wrap: true,
    },
    {
      name: "AC Number",
      selector: (row) => row.acc_no,
    },
    {
      name: "AC IFSC",
      selector: (row) => row.ifsc,
    },
    {
      name: "AC Type",
      selector: (row) => row.acc_type,
    },
    {
      name: "AC Limit",
      selector: (row) => currencySetter(row.acc_limit),
    },
    {
      name: "Actions",
      selector: (row) => (
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          {/* edit */}

          <CreateEditLimitAccount edit row={row} refresh={refresh} />
          <AdminDeleteLimitedAccounts row={row} refresh={refresh} />
        </Box>
      ),
      width: "150px",
    },
  ];

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <CreateEditLimitAccount refresh={refresh} />

          <Tooltip title="refresh">
            <IconButton
              aria-label="refresh"
              // color="success"
              sx={{
                color: "#0F52BA",
              }}
              onClick={() => {
                refreshFunc(setQuery);
              }}
            >
              <CachedIcon className="refresh-purple" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <ApiPaginate
            apiEnd={ApiEndpoints.ADMIN_ACCOUNTS_LIMITS}
            columns={columns}
            apiData={apiData}
            tableStyle={CustomStyles}
            setApiData={setApiData}
            queryParam={query || ""}
            returnRefetch={(ref) => {
              refresh = ref;
            }}
            ExpandedComponent={null}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminAccountLimit;
